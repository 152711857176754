import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  z-index: 3;
  @media only screen and (min-width: 576px) {
    max-width: 540px;
    margin: auto;
  }
  @media only screen and (min-width: 768px) {
    max-width: 720px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 960px;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1320px;
  }
`
