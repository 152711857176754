import React from "react"
import Logo from "../../assets/svg/logo.svg"
import { useAppState } from "../../store/context/app-context"
import { useMenuDispatch } from "../../store/context/menu-context"
import Menu from "../Menu"
import Container from "../UI/Container"
import { InnerWrapper, MenuToggler, Wrapper } from "./styles"

const Header = props => {
  const dispatch = useMenuDispatch()
  const state = useAppState()
  const toggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
    })
  }

  const position = state?.data?.position
  const height = state?.data?.height

  return (
    <Wrapper
      gradient={props.gradient}
      change={position - 80 < 0 && "change"}
      initial={Math.abs(position) > height && "initial"}
    >
      <Container>
        <InnerWrapper>
          <a href="/">
            <img src={Logo} alt="logo" />
          </a>
          <MenuToggler onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </MenuToggler>
          <Menu />
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Header
