import React from "react"
import ReactDOM from "react-dom"
import { useMenuDispatch, useMenuState } from "../../store/context/menu-context"
import MenuQuery from "./MenuQuery"
import { Close, MenuWrapper, Wrapper } from "./styles"
import { CSSTransition } from "react-transition-group"

const Menu = React.forwardRef((_, ref) => {
  const dispatch = useMenuDispatch()
  const toggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
    })
  }
  const { open } = useMenuState() || {}

  if (typeof document === "undefined") return null

  return ReactDOM.createPortal(
    <Wrapper toggled={open}>
      <CSSTransition in={open} timeout={250}>
        <MenuWrapper ref={ref}>
          <Close onClick={toggleMenu} />
          <MenuQuery />
        </MenuWrapper>
      </CSSTransition>
    </Wrapper>,
    document.body
  )
})

export default Menu
