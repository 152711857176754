import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  position: fixed;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgb(44, 48, 54), rgba(44, 48, 54, 0));
  z-index: 3;
  transition: 0.5s ease;
  min-height: 120px;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease;
    z-index: 1;
    ${({ gradient }) =>
      gradient === "dark" &&
      css`
        background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
      `}
    ${({ gradient }) =>
      gradient === "green" &&
      css`
        background: linear-gradient(
          to bottom,
          rgb(30, 33, 35),
          rgba(30, 33, 35, 0)
        );
      `}
    ${({ gradient }) =>
      gradient === "light" &&
      css`
        background: linear-gradient(
          to bottom,
          rgb(30, 33, 35),
          rgba(30, 33, 35, 0)
        );
      `}
  }
  img {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 1200px) {
    transition: 0.25s ease;
  }

  ${props =>
    props.gradient === "light" &&
    css`
      background: linear-gradient(
        to bottom,
        rgb(175, 143, 123),
        rgba(175, 143, 123, 0)
      );
    `}
  ${props =>
    props.gradient === "green" &&
    css`
      background: linear-gradient(
        to bottom,
        rgb(24, 55, 61),
        rgba(24, 55, 61, 0)
      );
    `}
    ${props =>
    props.change === "change" &&
    css`
      transition: 0.5s ease;
      background: unset;
      &:after {
        opacity: 1;
      }
    `}
    ${props =>
    props.initial === "initial" &&
    css`
      transition: 0.5s ease;
      background: linear-gradient(
        to bottom,
        rgb(44, 48, 54),
        rgba(44, 48, 54, 0)
      );
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
        transition: 0.5s ease;
        z-index: 2;
      }
    `}
`

export const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  z-index: 3;
  a {
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const MaxHeight = styled.div`
  height: 100%;
`
export const MenuToggler = styled.div`
  z-index: 3;
  position: relative;
  width: 35px;
  height: 20px;
  cursor: pointer;
  margin-right: auto;
  z-index: 3;
  span {
    z-index: 4;
    display: block;
    background-color: #fff;
    position: absolute;
    height: 3px;
    border-radius: 2rem;
    &:nth-child(1) {
      top: 0;
      width: 26px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
    &:nth-child(3) {
      bottom: 0;
      width: 26px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`
