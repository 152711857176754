import { graphql, useStaticQuery } from "gatsby"
import { node } from "prop-types"
import React from "react"
import { SocialMedia } from "./styles"
import Container from "../UI/Container"
import { Info, InnerWrapper, Pages, Wrapper } from "./styles"
import fb from "../../assets/svg/fb-secondary.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            id
            link
            slug
            title
          }
        }
      }
    }
  `)

  return (
    <Wrapper id="kontakt">
      <Container>
        <InnerWrapper>
          <Info>
            <h3>Võtke meiega ühendust</h3>
            <p>Jürgen Lip</p>
            <a href="tel: +372 5112973">+372 5112973</a>
            <a href="mailto: info@maitsvadmaitsed.ee">
              info@maitsvadmaitsed.ee
            </a>
            <p>Maitsvad Maitsed OÜ</p>
            <p>Reg.14799751</p>
            <p>VAT EE102187902</p>
            <p>IBAN EE302200221072326397 Swedbank</p>
          </Info>
          <Pages>
            <SocialMedia desktop>
              <a href="https://www.facebook.com/maitsvadmaitsed">
                <img src={fb} alt="fb" />
              </a>
            </SocialMedia>
            {data.allWpPage.edges.map(item => {
              if (item.node.slug !== "avaleht") {
                return (
                  <a key={item.node.id} href={item.node.link}>
                    {item.node.title}
                  </a>
                )
              }
            })}
            <SocialMedia>
              <a href="https://www.facebook.com/maitsvadmaitsed">
                <img src={fb} alt="fb" />
              </a>
            </SocialMedia>
          </Pages>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Footer
