import styled, { css } from "styled-components"
import arrow from "../../assets/svg/arrow.svg"

export const Wrapper = styled.div`
  z-index: 500;
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: -100vh;
  transition: 0.25s ease;
  ${props =>
    props.toggled &&
    css`
      transition: 0.25s ease;
      top: 0;
    `}
`

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  height: 30px;
  width: 30px;
  background-image: url(${arrow});
  background-size: contain;
  background-repeat: no-repeat;
  @media only screen and (min-width: 1200px) {
    top: 75px;
    left: 200px;
    width: 45px;
  }
`

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.9);
  transition: 0.25s ease;
  padding: 2rem;
  @media (min-width: 1024px) {
    padding: 2rem 4rem;
  }
`

export const MenuItemsWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  a,
  button {
    font-family: "JosefinSans-Regular";
    color: #fff;
    font-weight: 400;
    display: block;
    text-decoration: none;
    font-size: 45px;
    line-height: 54px;
    margin-bottom: 1.5rem;
    background-color: unset;
    border: none;
  }
  button {
    margin: 0;
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 0;
  }
  @media (min-width: 1024px) {
    a,
    button {
      font-size: 64px;
      line-height: 90px;
    }
  }
  @media only screen and (min-width: 1200px) {
    left: 200px;
    a,
    button {
      font-size: 78px;
      line-height: 120px;
      transition: 0.25s ease;
      margin-bottom: 0rem;
      &:hover {
        transition: 0.25s ease;
        transform: translateY(-20px);
        color: #e2c6a9;
      }
    }
  }
`
