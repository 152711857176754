import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMenuDispatch } from "../../store/context/menu-context"
import { MenuItemsWrapper } from "./styles"
import smoothscroll from "smoothscroll-polyfill"

const MenuQuery = () => {
  smoothscroll.polyfill()
  const dispatch = useMenuDispatch()
  const toggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
    })
  }

  const elem = document.getElementById("kontakt")

  const scroll = () => {
    elem.scrollIntoView({
      behavior: "smooth",
    })
  }

  const menuItems = useStaticQuery(graphql`
    query {
      allWpMenu {
        nodes {
          id
          menuItems {
            nodes {
              id
              label
              url
              path
            }
          }
        }
      }
    }
  `)

  return (
    <MenuItemsWrapper>
      {menuItems.allWpMenu.nodes.map(node => {
        return node.menuItems.nodes.map(item => {
          if (item.label === "Avaleht") {
            return (
              <a onClick={toggleMenu} key={item.id} href="/">
                {item.label}
              </a>
            )
          } else {
            return (
              <a onClick={toggleMenu} key={item.id} href={item?.url || "/"}>
                {item.label}
              </a>
            )
          }
        })
      })}
      <button
        id="scroll"
        onClick={() => {
          toggleMenu()
          scroll()
        }}
        href="#kontakt"
      >
        Kontakt
      </button>
    </MenuItemsWrapper>
  )
}

export default MenuQuery
