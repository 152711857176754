import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  background-color: #22262c;
`

export const InnerWrapper = styled.div`
  padding-top: 2rem;
  padding-bottom: 5rem;
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 3rem 0;
  }
  @media only screen and (min-width: 1200px) {
    padding: 4rem 0;
    padding-bottom: 10rem;
  }
`

export const Info = styled.div`
  margin: 2rem 0;
  p {
    color: #fff;
    font-family: "JosefinSans-Regular";
    margin: 0;
    &:nth-last-of-type(4) {
      margin-top: 2rem;
    }
  }
  a {
    color: #fff;
    font-family: "JosefinSans-Regular";
    text-decoration: none;
    display: block;
    &:nth-child(1) {
      margin-bottom: 2rem;
    }
  }
  h3 {
    color: #fff;
    font-family: "JosefinSans-Regular";
    margin: 2rem 0;
    font-weight: 400;
  }
  @media only screen and (min-width: 1200px) {
    margin: unset;
    p {
      line-height: 30px;
    }
    a {
      line-height: 39px;
      font-size: 23px;
      margin: 0;
    }
    h2 {
      font-size: 32px;
      line-height: 82px;
    }
    h3 {
      font-size: 32px;
    }
  }
`

export const Pages = styled.div`
  margin: 2rem 0;
  a {
    display: block;
    color: #fff;
    font-family: "JosefinSans-Regular";
    text-decoration: none;
    font-size: 30px;
    line-height: 40px;
  }
  @media (min-width: 1024px) {
    a {
      transition: 0.25s ease;
      &:hover {
        color: #e2c6a9;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    margin: unset;
    a {
      font-size: 40px;
      margin: 0;
      line-height: 70px;
    }
  }
`

export const SocialMedia = styled.div`
  margin: 2rem 0;
  a {
    display: block;
    img {
      height: 35px;
    }
  }
  @media (min-width: 1024px) {
    display: none;
  }
  ${props =>
    props.desktop &&
    css`
      display: none;
      @media (min-width: 1024px) {
        display: block;
        margin: 1rem 0;
        a {
          line-height: 10px;
          img {
            height: auto;
          }
        }
      }
    `}
`
